@font-face {
  font-family: 'KIMM_Bold';
  //src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2212@1.0/KIMM_Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

.research_main.y2021 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 600px;
  margin-top: 200px;

  .tab {
    display: flex;
    align-self: flex-start;
    flex-wrap: wrap;

    li {
      padding: 4px;
      margin: 4px 10px 10px 4px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: rgb(174, 174, 174);
      font-size: 20px;

      &.on {
        font-weight: bold;
        color: #2b77ad;
        border-bottom: 3px solid #2b77ad;
      }
      &.on:hover{
        border-bottom: 3px solid #2b77ad;
      }
    }

    li:hover {
      border-bottom: 2px solid #ededed;
    }

    li:last-child {
      border-right: none;
    }

  }

  .table-container {
    margin-left: 200px;

    img {
      width: 45%;
      margin: auto auto;
      display: flex;
    }

    h1 {
      font-weight: 200;
      font-size: 22px;
      margin: 5px 0 10px 0;
      background-color: rgb(255, 255, 209);
      display: inline-block;
      font-family: 'KIMM_Bold';
      font-weight: 800;
    }

    .table-tb {
      display: flex;

      .table-lr {
        margin-right: 20px;
        height: 465px;

        h2{
          height: 30px;
          line-height: 30px;
          padding-left: 10px;
          font-weight: bold;
          font-size: 1.1em;
          color: #fff;
          // background: linear-gradient(90deg, #008CCE 0%, #36236B 50%, #C60026 100%);
          background-color: #00A5B5;
          border-radius: 5px 5px 0 0;
          text-align: center;
          width: 100%;
        }

        .data_table {
          flex: 1;
          margin-bottom: 15px;
          width: 650px;
          height: 200px;
          font-size: 12px;

          thead {
            font-size: 12px;
            font-weight: normal;

            tr:first-child, tr:nth-child(2) {
              color: black;
              background-color: #ededed;
            }
          }

          td,th {
            border: 1px solid #bbbbbb;
            text-align: center;
          }
        }
      }

      .table-rl {
        margin-right: 20px;
        height: 250px;

        h2{
          height: 30px;
          line-height: 30px;
          padding-left: 10px;
          font-weight: bold;
          font-size: 1.1em;
          color: #fff;
          // background: linear-gradient(90deg, #008CCE 0%, #36236B 50%, #C60026 100%);
          background-color: #00A5B5;
          border-radius: 5px 5px 0 0;
          text-align: center;
          width: 100%;
        }

        .data_table {
          flex: 1;
          margin-bottom: 15px;
          width: 650px;
          height: 200px;
          font-size: 12px;

          thead {
            font-size: 12px;
            font-weight: normal;

            tr:first-child, tr:nth-child(2) {
              color: black;
              background-color: #ededed;
            }
          }

          td,th {
            border: 1px solid #bbbbbb;
            text-align: center;
          }
        }
      }
    }
  }
}
