//@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;700&display=swap');

$en_ft : 'Baloo Bhai 2', cursive;

.loginWrap {
	position: relative; display: flex; justify-content: center; align-items: center; height: 100vh; background: #ebeeef;

	> ul {
		position: relative; max-width: 35em; width: 100%;

		> li {
			width: 100%;

			&:last-child {position: absolute; width: 0; height: 100%; left: 30%; top: 0; z-index: 10; overflow: hidden; box-shadow: 0px 10px 34px -15px rgb(0 0 0 / 24%); background: #fff; border-radius: 0 1em 1em 0; transition: all .15s;  }

			&.on {
				// &:first-child {}
				&:last-child {width: 100%; }
			}

			.form_group {
				margin-bottom: 1em;

				label {margin-top: .75em; color: #808080; }
			}
			.input_group {
				display: block !important;

				input {font-size: .875em; border: 0; border-bottom: 1px solid #b2b2b2; border-radius: 0; }
			}

			.btn {background: #285f74; color: #fff; font-weight: 700; padding: .75em 3.25em !important; border-radius: 3em; }
		}
	}

	.loginInner {
		$bdr: 1em;

		text-align: left;

		.inner_header {
			position: relative; text-align: center; color: #fff; border-radius: $bdr $bdr 0 0; height: 11.25em; overflow: hidden;
			display: flex; align-items: center; justify-content: center;

			&::after {display: block; content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(54,84,99,0.7); }

			img {position: absolute; left: 0; top: 0; width: 100%; height: 100%; }
			h2 {position: relative; text-transform: uppercase; font-size: 1.75em; z-index: 1; color: #ffffff;}
		}

		.inner_body {
			padding: 3em; background: #fff;

			.loginDtlWrap {
				display: flex; justify-content: space-between; align-items: center; margin-left: 8.8em; font-family: $en_ft; margin-top: 1em;

				li {
					display: flex; align-items: center;
					label { margin-left: 1em; color: #808080; }
				}
			}
		}

		.inner_footer {
			background: #fff; padding: 3em; padding-top: 0; border-radius: 0 0 $bdr $bdr; padding-left: 10.5em;

			.signUpWrap {
				margin-top: 3.5em;

				a {
					font-size: .875em; color: #285f74;

					&:hover {text-decoration: underline;}
				}
			}
		}
	}

	.findInner {
		position: relative; height: 100%; padding: 5em 3em;

		.inner_header {
			text-align: center; margin-bottom: 3em;
		}

		.inner_footer {
			padding-top: 3em; padding-left: 7.5em;

			.btn {width: 12.5em; }
		}

		a {font-family: $en_ft}
	}
}
