#analyPage {
    height: calc(100vh - 70px);
    background-color: #f8f8f9;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;


  .sgg_tab {
    display: flex;
    align-items: center;

    li {
      font-size: 24px;
      color: #909090;
      padding: 2px;
      margin-right: 20px;
      cursor: pointer;

      &.on {
        font-weight: bold;
        color: #00A5B5;
      }
    }
    :hover {
      color: #00A5B5;
    }
  }

  .analy_wrap {
    width: 90%;
    padding: 6px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 0.3;

    .tab_wrap {
      width: 350px;
      height: 55vh;
      background-color: white;
      border-radius: 9px;
      margin: auto 10px;
      margin-left: 0;
      padding-top: 10px;
      border: 0.1px solid rgb(238, 238, 238);

      .select_tab {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 300px;
        margin: 0 auto;

        .ant-select-selector {
          border-radius: 5px;
        }

        li {
          padding: 4px;
          margin: 4px 4px 10px 4px;
          cursor: pointer;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          color: rgb(174, 174, 174);

          &.on {
            font-weight: bold;
            color: #00A5B5;
            border-bottom: 3px solid #00A5B5;
          }
          &.on:hover{
            border-bottom: 3px solid #00A5B5;
          }
        }
        li:hover {
          border-bottom: 2px solid #ededed;
        }
      }


      .input_wrap {
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        width: 300px;


        .input {
          margin: 5px 0;

          label {
            display: inline-block;
            width: 60px;
          }
        }

        .ant-form-item {
          margin: 12px 0;
        }

        .ant-form-item-label {

          width: 30%;
        }

        .ant-col .ant-form-item-control {
          margin-left:2%;
        }

        .ant-input-number {
          width: 80%;
          border-radius: 5px;
        }

        .ant-col {
          direction: rtl;
        }

        .ant-btn {
          margin-top: 7px;
          width: 100%;
          height: 40px;
          background-color: #00A5B5;
          border: #00A5B5;
          border-radius: 9px;
        }

        .ant-form-item-label>label:after {
          content: none;
        }
      }

      .input {
        flex-direction: column;
      }
    }

    .section1 {
      flex: 1;
      display: flex;
      justify-content: center;
      height: 55vh;
      background-color: white;
      border: 0.1px solid rgb(238, 238, 238);
      border-radius: 10px;
      margin: auto auto;

      .chart_wrap {
        flex: 1;
        padding: 12px;
      }
  }
  }

  .section2 {
    flex: 0.7;
    display: flex;
    margin-top: 4px;
    width: 90%;
    text-align: center;
    position: relative;

    .button {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 6px;

      .btn_1 {
        background-color: #00A5B5;
        margin-right: 10px;
        padding: 4px;
        border-radius: 4px;
        color: white;
        font-weight: 800;
        width: 70px;
        height: 25px;
        cursor: pointer;
      }

      .btn_2 {
        background-color: #00A5B5;
        margin-right: 10px;
        padding: 4px;
        border-radius: 4px;
        color: white;
        font-weight: 800;
        width: 70px;
        height: 25px;
        cursor: pointer;
      }

      .btn_3 {
        background-color: #1c8d3d;
        padding: 4px;
        border-radius: 4px;
        color: white;
        font-weight: 800;
        width: 70px;
        height: 25px;
        cursor: pointer;
      }
    }

    .ag-paging-panel {
      background-color: #f8f8f9;
    }

    .ag-header-cell-comp-wrapper {
      justify-content: center;
    }

    .ant-btn {
      margin: 5px auto;
      width: 4%;
      height: 25px;
      background-color: #00A5B5;
      border: #00A5B5;
      border-radius: 9px;
      text-align: center;
      position: absolute;
      top: 15px;
      right: -80px;
      color: white;
    }
  }
}

#analyChartPage {
  @keyframes blink {
    0% {
      // stroke: rgb(0, 255, 42);
      // stroke-width: 3px;
      opacity: 1;
      // box-shadow: 2px 2px 10px black;
    }
    50% {
      stroke: transparent;
      opacity: 0.2;
      // box-shadow: none;
    }
    100% {
      // stroke: rgb(0, 255, 42);
      // stroke-width: 3px;
      opacity: 1;
      // box-shadow: 2px 2px 10px black;
    }
  }

  .highlighted {
    animation: blink 1.5s infinite;
  }

  .recharts-reference-line {
    -ms-user-select: none;      
    -khtml-user-select: none;   
    -webkit-user-select: none;   
    user-select: none;
  }

  .recharts-rectangle .recharts-reference-area-rect {
    border-radius: 10px;
  }

  .recharts-tooltip-wrapper P {
    -ms-user-select: none;      
    -khtml-user-select: none;   
    -webkit-user-select: none;   
    user-select: none;
  }
}

