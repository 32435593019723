@charset "utf-8";

/* input type="number" 의 증감 화살표 제거 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.pageWrap.p_status .btnWrap {
  justify-content: space-between;
}

/* 엑셀 템플릿 */
.tableWrap .content {
    height: 100%;
    border-top: 2px solid black;
    border-left: 1px solid black;
    display: grid;
    grid-template-rows: repeat(19, 1fr);
    grid-template-columns: repeat(18, 1fr);
}

/* 엑셀 템플릿 */
.tableWrap2 .content {
    grid-template-rows: repeat(19, 1fr);
    grid-template-columns: repeat(15, 1fr);
}

.tableWrap .content div {
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tableWrap .content .valueDiv{
    justify-content: right;
    padding: 0 4px;
}

.tableWrap .content div .valueDiv{
    background-color: rgb(255, 255, 184);
    height: 100%;
    justify-content: right;
    align-items: right;
    text-align: right;
}

.tableWrap .content .subTitle {
    background-color: rgb(255, 255, 184);
    padding: 2px;
}

.tableWrap .content div input {
    border: none;
    width: 100%;
    padding: 2px;
    text-align: center;
}

/* grid 병합 */
.col_2 {
    grid-column: span 2;
}

.col_3 {
    grid-column: span 3;
}

.col_9 {
    grid-column: span 9;
}

.row_2 {
    grid-row: span 2;
}

.row_3 {
    grid-row: span 3;
}

.row_5 {
    grid-row: span 5;
}

.row_6 {
    grid-row: span 6;

}

.vrlr {
    writing-mode: vertical-lr;
}
